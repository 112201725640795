import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

const Seo = ({ lang, title, meta, description, keywords, image }) => {
  const { site } = useStaticQuery(graphql`
    query {
      site: site {
        siteMetadata {
          title
          description
          keywords
          siteUrl
        }
      }
    }
  `)

  const resize = (image, params) => {
    let file = image
    let imageService = "https://img2.storyblok.com/"
    let path = file.replace("https://a.storyblok.com", "")
    let newFile = imageService + params + "/filters:quality(75)" + path

    return newFile
  }

  const metaTitle = title || site.siteMetadata.title
  const metaImage = image
    ? resize(image, "1100x619")
    : "https://a.storyblok.com/f/97754/1100x619/112e3f9c68/seo-fallback.jpg"
  const metaDescription = description || site.siteMetadata.description
  const metaKeywords = keywords || site.siteMetadata.keywords

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={metaTitle}
      meta={[
        {
          name: `viewport`,
          content: `width=device-width, initial-scale=1, maximum-scale=1, shrink-to-fit=no`,
        },
        {
          property: `image`,
          content: metaImage,
        },
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `keywords`,
          content: metaKeywords,
        },
        {
          name: `language`,
          content: lang,
        },
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:image`,
          content: metaImage,
        },

        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: "twitter:card",
          content: `summary_large_image`,
        },
        {
          name: `twitter:title`,
          content: metaTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:keywords`,
          content: metaKeywords,
        },
        {
          property: `twitter:image`,
          content: metaImage,
        },
        {
          property: "og:image:width",
          content: "1100",
        },
        {
          property: "og:image:height",
          content: "619",
        },
      ].concat(meta)}
    >
      <script
        src="https://use.fontawesome.com/releases/v5.15.4/js/all.js"
        crossorigin="anonymous"
      ></script>
    </Helmet>
  )
}

Seo.defaultProps = {
  lang: `en`,
  meta: [],
  title: ``,
  description: ``,
  keywords: ``,
  image: ``,
}

Seo.propTypes = {
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
  description: PropTypes.string,
  keywords: PropTypes.string,
}

export default Seo
